import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {Button, TextField} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import Moment from "react-moment";
import './AllCars.scss'
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";

export const AllCars = () => {
    const history = useNavigate();
    const param = useParams();
    const [cars, setCars] = useState([]);
    useEffect(()=>{
        axios.get(`https://libertpro.com/brands/${param.id}/models/`).then(result=>{
                setCars(result.data);
            }).catch(err=>{console.log(err)});
    },[])
    const handlePackageDelete = (model_id) => {
        axios.get(`https://libertpro.com/brands/${param.id}/models/${model_id}/delete/`).then(result=>{
            axios.get(`https://libertpro.com/brands/${param.id}/models/`).then(result=>{
                setCars(result.data);
            }).catch(err=>{console.log(err)});
        }).catch(err=>{console.log(err)});
    }
    return(
        <div style={{width:'100%'}}>
            <div className='brand-head'>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <h2>Marka Modeller</h2>
                    <Button onClick={()=>{history(`/create_car/${param.brand}/${param.id}`)}} style={{marginLeft:'50px'}} className='button' variant='outlined' endIcon={<AddIcon />}>model ekle</Button>
                </div>
                <p style={{color:'white', display:'flex', alignItems:'center'}}><a href={'/'}>ana Sayfa</a><KeyboardArrowRightIcon/>marka</p>
            </div>
            <div style={{overflowY:'auto', height:'80vh', marginTop: 40}}>
                <table className={'cars_table'}>
                    <thead>
                        <tr>
                            <td style={{width:70,fontWeight:'bold'}}>ID</td>
                            <td>Marka</td>
                            <td>Karouser</td>
                            <td>Дата</td>
                            <td>Автор</td>
                            <td>Удалить</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cars.map((model)=>(
                                <tr key={model.id}>
                                    <td style={{width:70, textAlign:'center', fontWeight:'bold'}} onClick={()=>{history(`/edit_car/${param.brand}/${param.id}/${model.id}`)}}>{model.id}</td>
                                    <td onClick={()=>{history(`/edit_car/${param.brand}/${param.id}/${model.id}`)}}>{model.name}</td>
                                    <td onClick={()=>{history(`/edit_car/${param.brand}/${param.id}/${model.id}`)}}>{model.body}</td>
                                    <td onClick={()=>{history(`/edit_car/${param.brand}/${param.id}/${model.id}`)}}>
                                        <div className={'date'}>
                                            <Moment format="HH:MM:SS:MS">{model.created_at}</Moment>
                                            <Moment format="DD.MM.YYYY">{model.created_at}</Moment>
                                        </div>
                                    </td>
                                    <td className={'text-center'} onClick={()=>{history(`/edit_car/${param.id}/${model.id}`)}}>{model.created_by.username}</td>
                                    <td className={'text-center'}><DeleteIcon style={{color:'white', width:20, zIndex: 100}} onClick={() => {
                                        handlePackageDelete(model.id)
                                    }}/></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
