export const Hints = [
    {
        name:'Package',
        hint: 'Описание комплектации',
    },
    {
        name: 'Motor',
        hint: 'Двигатель',
    },
    {
        name: 'Yakıt tüketimi ve çevre dostu',
        hint: 'Расход топлива и экологичность',
    },
    {
        name: 'Hız Aşırtma ve İşleme',
        hint: 'Разгон и Управление',
    },
    {
        name: 'Şanzıman ve çekiş',
        hint: 'Привод и Трансмиссия',
    },
    {
        name: 'Frenler ve Süspansiyon',
        hint: 'Тормоза и Подвеска',
    },
    {
        name: 'Ölçüler ve Ağırlıklar',
        hint: 'Размеры и Вес',
    },
    {
        name: 'Arazi performansı',
        hint: 'Внедорожные характеристики',
    },
    {
        name: 'Tasarım ve Stil',
        hint: 'Дизайн и стиль, сюда пишем все что относится к внешнему облику автомобиля',
    },
    {
        name: 'Rahatlık ve Fonksiyonellik',
        hint: 'Все что внутри машины, комфорт, функциональность, интерьер, мультимедиа',
    },
    {
        name: 'Güvenlik',
        hint: 'Безопастность',
    },
    {
        name: 'Опции',
        hint: 'Добавить Опцию => Называть опцию и псставить цену',
    },
]

export const Model = [
    {
        name: 'name',
        type: 'text',
        label: 'Model',
        hint: 'Модель',
        placeholder: 'Название Модели',
        required: true,
    },
    {
        name: 'link',
        type: 'text',
        label: 'url',
        hint: 'Ссылка на модель на сайте производителя',
        placeholder: 'https://www.example.com.tr/',
        required: true,
    },
    {
        name: 'years',
        type: 'number',
        label: 'Garanti süresi, yıl',
        hint: '',
        placeholder: 'цифра, например: 2',
        required: false,
    },
    {
        name: 'kms',
        type: 'number',
        label: 'Garanti süresi, km',
        hint: '',
        placeholder: 'цифра, например: 100000',
        required: false,
    },
    {
        name: 'body',
        type: 'select',
        label: 'Karoser',
        hint: 'Кузов',
        options: [
            'sedan',
            'hatchback',
            'MPV',
            'SUV',
        ],
        placeholder: 'Sedan',
        required: true,
    },
    {
        name: 'type',
        type: 'select',
        label: 'ulaşım türü',
        hint: 'вид транспорта',
        options: [
            'Ticari araç',
            'Araba',
        ],
        placeholder: '',
        required: true,
    },
]


export const Gallery = [
    {
        name: 'image',
        type: 'file',
        label: 'Фото',
        hint: 'Картинка не должна весить больше 2мб',
        placeholder: '',
        required: true,
    },
]


export const EuroNcap = [
    {
        name: 'adult_pass',
        type: 'number',
        label: 'Yetişkin yolcu güvenliği, %',
        hint: '',
        placeholder: 'цифра, например: 60',
        required: false,
    },
    {
        name: 'child_pass',
        type: 'number',
        label: 'Çocuk Yolcu Güvenliği, %',
        hint: '',
        placeholder: 'цифра, например: 40',
        required: false,
    },
    {
        name: 'pedestrian',
        type: 'number',
        label: 'Yaya güvenliği, %',
        hint: '',
        placeholder: 'цифра, например: 30',
        required: false,
    },
    {
        name: 'assistant_work',
        type: 'number',
        label: 'Güvenlik asistanlarının çalışmaları, %',
        hint: '',
        placeholder: 'цифра, например: 40',
        required: false,
    },
    {
        name: 'link',
        type: 'url',
        label: 'Rapor bağlantısı',
        hint: 'Ссылка на отчет',
        placeholder: 'https://www.euroncap.com/en/results/cupra/born/46243',
        required: false,
    },
    {
        name: 'rate',
        type: 'select',
        label: 'Euro Ncap, yıldızlar',
        hint: '',
        options: [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
        ],
        placeholder: '1',
        required: false,
    },
]


export const Packages = [
    {
        name: 'fiyat',
        type: 'number',
        label: 'Fiyat, TRY',
        hint: 'Цена в турецких лирах',
        placeholder: 'цифра, например: 100000',
        required: true,
    },
    {
        name: 'name',
        type: 'text',
        label: 'Versiyon',
        hint: 'Комплектация',
        placeholder: 'Название комплектации',
        required: true,
    },
    {
        name: 'fuel_index',
        type: 'text',
        label: 'Motor indeksi',
        hint: 'Индекс двигателя',
        placeholder: 'Индекс двигателя Fire, GDI, T-GDI и т.п.',
        required: true,
    },
    {
        name: 'fuel_type',
        type: 'select',
        label: 'Yakıt',
        hint: 'Топливо',
        options: [
            'benzin',
            'dizel',
            'elektrik',
            'lpg',
            'hibrit benzin & lpg',
            'hibrit benzin & elektrik',
            'hibrit dizel & elektrik',
        ],
        placeholder: 'Benzin',
        required: true,
    },
    {
        name: 'transmiss',
        type: 'select',
        label: 'Vites Kutusu',
        hint: 'Коробка передач',
        options: [
            'otomatik',
            'manuel',
        ],
        placeholder: 'otomatik',
        required: true,
    },
    {
        name: 'year',
        type: 'select',
        label: 'Model yılı',
        hint: 'Год выпуска',
        options: [
            2020,
            2021,
            2022,
            2023,
            2024,
        ],
        placeholder: '2022',
        required: true,
    },
    {
        name: 'sites',
        type: 'number',
        label: 'Koltuk sayısı',
        hint: 'Количество посадочных мест',
        placeholder: 'цифра, например: 5',
        required: false,
    },
]
export const Motor = [
    {
        name: 'capacity_l',
        type: 'number',
        label: 'Motor hacmi, l',
        hint: 'Объем двигателя в литрах, разделитель "."',
        placeholder: 'цифра, например: 1.6',
        required: true,
    },
    {
        name: 'capacity_cc',
        type: 'number',
        label: 'Motor hacmi, cc',
        hint: 'Объем двигателя в куб.см, без пробелов',
        placeholder: 'цифра, например: 1598',
        required: true,
    },
    {
        name: 'power_hp',
        type: 'number',
        label: 'Motor gücü, hp',
        hint: 'Мощность двигателя в лошадиных силах',
        placeholder: 'цифра, например: 95',
        required: true,
    },
    {
        name: 'power_rpm',
        type: 'number',
        label: 'Motor gücü, rpm',
        hint: 'Мощность двигателя в оборотах',
        placeholder: 'цифра, например: 5000',
        required: true,
    },
    {
        name: 'power_kw',
        type: 'number',
        label: 'Motor gücü, kW',
        hint: 'Мощность двигателя в киловатах',
        placeholder: 'цифра, например: 70',
        required: false,
    },
    {
        name: 'torque',
        type: 'number',
        label: 'Tork, Nm',
        hint: 'Крутящий момент в Nm',
        placeholder: 'цифра, например: 127',
        required: true,
    },
    {
        name: 'torque_min',
        type: 'number',
        label: 'Min. Tork, rpm',
        hint: 'Нижний порог оборотов макс. крутящего момента',
        placeholder: 'цифра, например: 1500',
        required: false,
    },
    {
        name: 'torque_max',
        type: 'number',
        label: 'Max. Tork, rpm',
        hint: 'Верхний порог оборотов макс. крутящего момента',
        placeholder: 'цифра, например: 4500',
        required: true,
    },
    {
        name: 'cylinder',
        type: 'number',
        label: 'Silindir adedi',
        hint: 'Количество цилиндров',
        placeholder: 'цифра, например: 4',
        required: true,
    },
    {
        name: 'valve',
        type: 'number',
        label: 'Valf adedi',
        hint: 'Количество клапанов',
        placeholder: 'цифра, например: 16',
        required: false,
    },
    {
        name: 'compression',
        type: 'number',
        label: 'Sıkıştırma Oranı',
        hint: 'Степень сжатия',
        placeholder: 'цифра, например: 10',
        required: false,
    },
    {
        name: 'piston',
        type: 'text',
        label: 'Çap x Strok, mm',
        hint: 'Диаметр и ход поршня',
        placeholder: 'цифры, например: 70 x 76',
        required: false,
    },
    {
        name: 'fuel_system',
        type: 'text',
        label: 'Yakıt sistemi',
        hint: 'Топливная система',
        placeholder: 'текст или цифры, например: Common Rail 2.0',
        required: false,
    },
]

export const Electric = [
    {
        name: 'hybrid_type',
        type: 'select',
        label: 'hibrit tipi',
        hint: 'Тип гибрида',
        options: [
            'N/A',
            'MHEV',
            'PHEV',
            'HEV',
        ],
        placeholder: '',
        required: true,
    },
    {
        name: 'e_motor_type',
        type: 'text',
        label: 'Elektrikli Motor Tip',
        hint: 'Тип системы электродвигателя',
        placeholder: 'текст, Sistem adı',
        required: true,
    },
    {
        name: 'e_battery_type',
        type: 'text',
        label: 'Hibrit Sistem Pil Tipi',
        hint: 'Тип батареи например: Lityum iyon batarya',
        placeholder: 'текст, Pil Tipi',
        required: true,
    },
    {
        name: 'e_hybrid_type',
        type: 'text',
        label: 'Hibrit Sistem Aküsü Tip',
        hint: 'Тип гибридной системы',
        placeholder: 'текст, Sistem adı',
        required: true,
    },
    {
        name: 'e_power_hp',
        type: 'number',
        label: 'Elektrikli Motor Maksimum güç, hp',
        hint: 'Мощность электро двигателя в лошадиных силах',
        placeholder: 'цифра, например: 95',
        required: true,
    },
    {
        name: 'e_power_kw',
        type: 'number',
        label: 'Elektrikli Motor Maksimum güç, kW',
        hint: 'Мощность электро двигателя в киловатах',
        placeholder: 'цифра, например: 95',
        required: true,
    },
    {
        name: 'e_power_front_kw',
        type: 'number',
        label: 'ön Elektrikli Motor Maksimum güç, kW',
        hint: 'Мощность переднего электро двигателя в киловатах',
        placeholder: 'цифра, например: 95',
        required: true,
    },
    {
        name: 'e_power_back_kw',
        type: 'number',
        label: 'arka Elektrikli Motor Maksimum güç, kW',
        hint: 'Мощность заднего электро двигателя в киловатах',
        placeholder: 'цифра, например: 95',
        required: true,
    },
    {
        name: 'e_torque',
        type: 'number',
        label: 'Elektrikli Motor Maksimum tork, Nm',
        hint: 'Куртящий момент электродвигателя в Nm',
        placeholder: 'цифра, например: 120',
        required: true,
    },
    {
        name: 'e_torque_min',
        type: 'number',
        label: 'Elektrikli Motor Min. Tork, rpm',
        hint: 'Нижний порог оборотов макс. крутящего момента, чаще всего 0',
        placeholder: 'цифра, например: 0',
        required: false,
    },
    {
        name: 'e_torque_max',
        type: 'number',
        label: 'Elektrikli Motor Max. Tork, rpm',
        hint: 'Нижний порог оборотов макс. крутящего момента, чаще всего 0',
        placeholder: 'цифра, например: 0',
        required: false,
    },
    {
        name: 'e_capacity_a',
        type: 'number',
        label: 'Elektrik batarya kapasitesi, Ah',
        hint: 'Емкость батареи в Ампер часах',
        placeholder: 'цифра, например: 50',
        required: true,
    },
    {
        name: 'e_capacity_kw',
        type: 'number',
        label: 'Elektrik batarya kapasitesi, kWh',
        hint: 'Емкость батареи в киловат в часах',
        placeholder: 'цифра, например: 50',
        required: true,
    },
]

export const Hybrid = [
    {
        name: 'hybrid_power_hp',
        type: 'number',
        label: 'Hibrit sistem gücü, hp',
        hint: 'Мощность всей гибридной системы в лошадиных силах',
        placeholder: 'цифра, например: 50',
        required: true,
    },
    {
        name: 'hybrid_torque',
        type: 'number',
        label: 'Hibrit sistem tork, Nm',
        hint: 'Крутящий момент всей гибридной системы',
        placeholder: 'цифра, например: 50',
        required: true,
    },
]



export const Fuel = [
    {
        name: 'consumption',
        type: 'number',
        label: 'Kombine tüketim, L/100 km',
        hint: 'Расход в смешанном цикле',
        placeholder: 'одна наибольшая цифра, например: 7',
        required: true,
    },
    {
        name: 'consumption_city',
        type: 'number',
        label: 'Şehiriçi tüketim, L/100 km',
        hint: 'Расход в городе',
        placeholder: 'одна наибольшая цифра, например: 8',
        required: false,
    },
    {
        name: 'consumption_road',
        type: 'number',
        label: 'Şehirdışı tüketim, L/100 km',
        hint: 'Расход на трассе',
        placeholder: 'одна наибольшая цифра, например: 9',
        required: false,
    },
    {
        name: 'tank',
        type: 'number',
        label: 'Yakıt Deposu, L',
        hint: 'Объем бака',
        placeholder: 'цифра, например: 50',
        required: true,
    },
    {
        name: 'co2_min',
        type: 'number',
        label: 'Min. CO2 Emisyonları, g/km',
        hint: 'Минимальное значение выбросов СО2',
        placeholder: 'цифра, например: 120',
        required: false,
    },
    {
        name: 'co2_max',
        type: 'number',
        label: 'Max. CO2 Emisyonları, g/km',
        hint: 'Максимальное значение выбросов СО2',
        placeholder: 'цифра, например: 135',
        required: true,
    },
    {
        name: 'emission_standard',
        type: 'text',
        label: 'Emisyon normu',
        hint: 'Название нормы выбросов',
        placeholder: 'текст и/или цифры, например: Euro 6',
        required: false,
    },
]


export const Control = [
    {
        name: 'acceleration',
        type: 'number',
        label: 'Hızlanma 0-100 km/h, s',
        hint: 'Разгон 0 - 100',
        placeholder: 'цифра, например: 8',
        required: true,
    },
    {
        name: 'speed',
        type: 'number',
        label: 'Maksimum hız, km/h',
        hint: 'Масимальная скорость',
        placeholder: 'цифра, например: 180',
        required: true,
    },
    {
        name: 'turn_radius',
        type: 'number',
        label: 'Dönüş yarıçapı, m',
        hint: 'Радиус разворота, внимательно некоторые пишут диаметр разворота, тогда нужно делить на 2',
        placeholder: 'цифра, например: 5.2',
        required: false,
    },
    {
        name: 'wheel_turns',
        type: 'number',
        label: 'Direksiyon kilitten kilide dönüyor',
        hint: 'Обороты руля от упор до упора',
        placeholder: 'цифра, например: 2.6',
        required: false,
    }
]


export const Transmission = [
    {
        name: 'transmission',
        label: 'Vites Kutusu',
        hint: 'Коробка передач',
        type: 'select',
        options: [
            'AT',
            'MT',
            'CVT',
            'CVT Lineartronic',
            'DCT',
            'EDC',
            'DSG',
            'AMT',
            'Electric',
            'AT Tiptronic',
            'X-Tronic CVT',
            'Multidrive S CVT',
            'e-CVT',
            'AT S tronic',
            'AT Steptronic',
            'AT Geartronic',
            'G-Tronic',
            'TCT',
            'PDK',
            'LDF',
        ],
        placeholder: 'AT',
        required: true,
    },
    {
        name: 'gears',
        label: 'Vites sayısı',
        hint: 'Количество передач',
        type: 'select',
        options: [
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
        ],
        placeholder: '6',
        required: true,
    },
    {
        name: 'clutch',
        type: 'text',
        label: 'Debriyaj Sistemi',
        hint: 'тип сцепления',
        placeholder: 'Debriyaj Sistemi',
        required: false,
    },
    {
        name: 'drive',
        type: 'select',
        label: 'Çekiş',
        hint: 'Привод',
        options: [
            'Ön',
            'Arka',
            '4x4',
        ],
        placeholder: 'Ön',
        required: true,
    },
]


export const Suspension = [
    {
        name: 'brake_front',
        type: 'text',
        label: 'Ön Fren',
        hint: 'Передние тормоза',
        placeholder: 'текст и/или цифры, например: Disk',
        required: false,
    },
    {
        name: 'brake_back',
        type: 'text',
        label: 'Arka Fren',
        hint: 'Задние тормоза',
        placeholder: 'текст и/или цифры, например: Kampana',
        required: false,
    },
    {
        name: 'front',
        type: 'text',
        label: 'Ön Süspansiyon',
        hint: 'Передняя подвеска',
        placeholder: 'текст и/или цифры, например: MacPherson',
        required: false,
    },
    {
        name: 'back',
        type: 'text',
        label: 'Arka Süspansiyon',
        hint: 'Задняя подвеска',
        placeholder: 'текст и/или цифры, например: MacPherson',
        required: false,
    },
    {
        name: 'front_damper',
        type: 'text',
        label: 'Ön Amortisörler',
        hint: 'передние амортизаторы',
        placeholder: 'текст например: Gazlı tip helezon yaylı amortisörler',
        required: false,
    },
    {
        name: 'back_damper',
        type: 'text',
        label: 'Arka Amortisörler',
        hint: 'задние амортизаторы',
        placeholder: 'текст например: Gazlı tip helezon yaylı amortisörler',
        required: false,
    },

]


export const Dimension = [
    {
        name: 'length',
        type: 'number',
        label: 'Uzunluk, mm',
        hint: 'Длина',
        placeholder: 'цифра, например: 4500',
        required: true,
    },
    {
        name: 'width',
        type: 'number',
        label: 'Genişlik, mm',
        hint: 'Ширина',
        placeholder: 'цифра, например: 1800',
        required: false,
    },
    {
        name: 'height',
        type: 'number',
        label: 'Yükseklik, mm',
        hint: 'Высота',
        placeholder: 'цифра, например: 1675',
        required: true,
    },
    {
        name: 'wheel_base',
        type: 'number',
        label: 'Aks Mesafesi, mm',
        hint: 'Колесная база, расстояние между осями',
        placeholder: 'цифра, например: 3200',
        required: false,
    },
    {
        name: 'width_front',
        type: 'number',
        label: 'Ön İz Genişliği, mm',
        hint: 'Ширина передней колеи',
        placeholder: 'цифра, например: 1545',
        required: false,
    },
    {
        name: 'width_back',
        type: 'number',
        label: 'Arka İz Genişliği, mm',
        hint: 'Ширина задней колеи',
        placeholder: 'цифра, например: 1535',
        required: false,
    },
    {
        name: 'overhang_front',
        type: 'number',
        label: 'Ön uzantı, mm',
        hint: 'Передний свес',
        placeholder: 'цифра, например: 1235',
        required: false,
    },
    {
        name: 'overhang_back',
        type: 'number',
        label: 'Arka uzantı, mm',
        hint: 'Задний свес',
        placeholder: 'цифра, например: 1200',
        required: false,
    },
    {
        name: 'trunk',
        type: 'number',
        label: 'Bagaj Hacmi, L',
        hint: 'Объем багажника',
        placeholder: 'цифра, например: 1200',
        required: true,
    },
    {
        name: 'trunk_saloon',
        type: 'number',
        label: 'Bagaj hacmi, arka koltuklar en önde, L',
        hint: 'Объем багажника, задние сиденья подвинуты вперед, л',
        placeholder: 'цифра, например: 1200',
        required: false,
    },
    {
        name: 'trunk_second',
        type: 'number',
        label: 'Bagaj Hacmi 2. sıra koltuklar yatırıldığında, L',
        hint: 'Объем багажника со сложенным вторым рядом',
        placeholder: 'цифра, например: 1200',
        required: false,
    },
    {
        name: 'weight_driver',
        type: 'number',
        label: 'Sürücü Dahil Ağırlık, kg',
        hint: 'Снаряженная масса с водителем',
        placeholder: 'цифра, например: 1200',
        required: false,
    },
    {
        name: 'weight_pass',
        type: 'number',
        label: 'Azami yüklü ağırlık, kg',
        hint: 'Максимальный вес авто с грузом и пассажирами',
        placeholder: 'цифра, например: 1500',
        required: false,
    },
    {
        name: 'trailer',
        type: 'number',
        label: 'Frensiz treyler ağırlığı, kg',
        hint: 'Вес прицепа без тормозов',
        placeholder: 'цифра, например: 700',
        required: false,
    },
    {
        name: 'trailer_brakes',
        type: 'number',
        label: 'Frenli treyler ağırlığı, kg',
        hint: 'Вес прицепа с тормозами',
        placeholder: 'цифра, например: 3000',
        required: false,
    },
    {
        name: 'tires_front',
        type: 'text',
        label: 'Ön lastikler',
        hint: 'Передние шины',
        placeholder: '235/45 R19',
        required: false,
    },
    {
        name: 'tires_back',
        type: 'text',
        label: 'Arka lastikler',
        hint: 'Задние шины',
        placeholder: '235/45 R19',
        required: false,
    },
]


export const OffRoad = [
    {
        name: 'clearance',
        type: 'number',
        label: 'Yerden yükseklik, mm',
        hint: 'Дорожный просвет (клиренс)',
        placeholder: 'цифра, например: 215',
        required: false,
    },
    {
        name: 'offroad_clearance',
        type: 'number',
        label: 'off-road yerden yükseklik , mm',
        hint: 'Дорожный просвет внедорожный (клиренс)',
        placeholder: 'цифра, например: 215',
        required: false,
    },
    {
        name: 'angle_front',
        type: 'number',
        label: 'Yaklaşma açısı, º',
        hint: 'Угол въезда (угол сближения)',
        placeholder: 'цифра, например: 26',
        required: false,
    },
    {
        name: 'angle_back',
        type: 'number',
        label: 'Uzaklaşma açısı, º',
        hint: 'Угол съезда (угол отдаления)',
        placeholder: 'цифра, например: 29',
        required: false,
    },
    {
        name: 'angle_ramp',
        type: 'number',
        label: 'Tepe açısı, º',
        hint: 'Угол рампа, пиковый угол, верхний угол',
        placeholder: 'цифра, например: 23',
        required: false,
    },
    {
        name: 'depth',
        type: 'number',
        label: 'Sudan geçme yüksekliği, mm',
        hint: 'Глубина брода',
        placeholder: 'цифра, например: 700',
        required: false,
    },
]


export const Paket = [
    {
        name: 'name',
        type: 'text',
        label: 'Paket adı',
        hint: 'Название пакета',
        placeholder: '',
        required: true,
    },
    {
        name: 'cost',
        type: 'number',
        label: 'Fiyat',
        hint: 'Цена',
        placeholder: '',
        required: true,
    },

]


export const Option = [
    {
        name: 'name',
        type: 'text',
        label: 'Seçenek adı',
        hint: 'Название опции',
        placeholder: '',
        required: true,
    },
    {
        name: 'cost',
        type: 'number',
        label: 'Fiyat',
        hint: 'Цена',
        placeholder: '',
        required: true,
    },

]


export const Salon = [
    {
        name: 'name',
        type: 'text',
        label: 'Название',
        hint: '',
        placeholder: '',
        required: true,
    },
    {
        name: 'address',
        type: 'text',
        label: 'Адрес',
        hint: '',
        placeholder: '',
        required: true,
    },
    {
        name: 'google',
        type: 'text',
        label: 'Ссылка на гугл',
        hint: '',
        placeholder: '',
        required: true,
    },
    {
        name: 'site',
        type: 'text',
        label: 'Сайт диллера от производителя',
        hint: 'Берется с сайта производителя',
        placeholder: '',
        required: false,
    },
    {
        name: 'phone_number',
        type: 'text',
        label: 'Мобильный телефон',
        hint: '',
        placeholder: '',
        required: false,
    },
    {
        name: 'city_number',
        type: 'text',
        label: 'Городской телефон',
        hint: '',
        placeholder: '',
        required: false,
    },
    {
        name: 'email',
        type: 'text',
        label: 'Почта диллера',
        hint: '',
        placeholder: '',
        required: false,
    },
    {
        name: 'email_captain',
        type: 'text',
        label: 'Почта руководства',
        hint: '',
        placeholder: '',
        required: false,
    },
    {
        name: 'email_sale',
        type: 'text',
        label: 'Почта отдел продаж',
        hint: '',
        placeholder: '',
        required: false,
    },
    {
        name: 'email_marketing',
        type: 'text',
        label: 'Почта отдел маркетинга',
        hint: '',
        placeholder: '',
        required: false,
    },
    {
        name: 'avatar',
        type: 'file',
        label: 'Картинка не должна весить больше 2мб',
        hint: '',
        placeholder: '',
        required: true,
    },
    {
        name: 'site_salon',
        type: 'text',
        label: 'Сайт автосалона',
        hint: 'Берется с google maps',
        placeholder: '',
        required: false,
    },
    {
        name: 'email_salon',
        type: 'text',
        label: 'Почта диллера',
        hint: '',
        placeholder: '',
        required: false,
    },

]




