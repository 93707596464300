import React, {useEffect, useRef, useState} from 'react';
import {
    Control,
    Dimension, Electric,
    EuroNcap,
    Fuel,
    Hints, Hybrid,
    Model,
    Motor,
    OffRoad,
    Packages,
    Suspension,
    Transmission
} from "../../data_capture/models";
import {Alert, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate, useParams} from "react-router";
import DeleteIcon from '@mui/icons-material/Delete';
import LiveHelpIcon from '@mui/icons-material/QuestionMark';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";

const Options = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].option_set && Object.keys(data[index_pac].option_set).length !== 0){
            let keys = Object.keys(data[index_pac].option_set).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        let copy_motor = { ...data[index_pac].option_set, [addcounter]: {name: "", cost:""}}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], option_set: copy_motor}
        setData(copy_data)
        setAddcounter(addcounter + 1)

    };
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[11].hint}>
                <h3 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}><span>{Hints[11].name}</span>  <span style={{fontSize:14}}>Количество опций: {additional.length}</span></h3>
            </Tooltip>
            {
                additional.map(
                    (pack, index) => (
                        // <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'security_set'} />
                        <InputOption key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Security = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].security_set.additional){
            let keys = Object.keys(data[index_pac].security_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[10].hint}>
                <h3 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}><span>{Hints[10].name}</span>  <span style={{fontSize:14}}>Количество опций: {additional.length}</span></h3>
            </Tooltip>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'security_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Comfort = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].comfort_set.additional){
            let keys = Object.keys(data[index_pac].comfort_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[9].hint}>
                <h3 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}><span>{Hints[9].name}</span>  <span style={{fontSize:14}}>Количество опций: {additional.length}</span></h3>
            </Tooltip>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'comfort_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Stil = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].still_set.additional){
            let keys = Object.keys(data[index_pac].still_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[8].hint}>
                <h3 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}><span>{Hints[8].name}</span>  <span style={{fontSize:14}}>Количество опций: {additional.length}</span></h3>
            </Tooltip>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'still_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const OffRoads = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].offroad_set.additional){
            let keys = Object.keys(data[index_pac].offroad_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        let copy_motor = { ...data[index_pac].offroad_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], offroad_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[7].hint}>
                <h3>{Hints[7].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    OffRoad.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].offroad_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].offroad_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'offroad_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Input = ({index_pac, data, setData, set_name, index_add, additional, setAdditional}) => {
    const ref1 = useRef()
    let [data1, setData1] = useState([])
    const handlePackageDelete = (event) => {
        event.preventDefault();
        let d  = [...additional]
        let counter = d.indexOf(index_add);
        d.splice(counter, 1);
        setAdditional(d);
        delete data[index_pac][set_name].additional[index_add];
    }
    const changeHandler = () => {
        data1 = ref1.current.value;
        let copy_motor = { ...data[index_pac][set_name], additional: {...data[index_pac][set_name].additional, [index_add]: data1}}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], [set_name]: copy_motor}
        setData(copy_data)
    }
    return (
        <div style={{display:'flex', maxWidth:300, marginTop:20}}>
            <input defaultValue={data[index_pac][set_name].additional !==null ? (data[index_pac][set_name].additional[index_add]) : ('')} type={"text"} ref={ref1} onChange={changeHandler} style={{marginRight:10, width: 250}} placeholder="Başlık, ölçü birimi" />
            <DeleteIcon style={{color:'white', width:20}} onClick={handlePackageDelete}/>
        </div>
    );
};
const InputOption = ({index_pac, data, setData, index_add, additional, setAdditional}) => {
    const ref = useRef()
    const ref1 = useRef()
    let [data1, setData1] = useState({
    })
    const handlePackageDelete = (event) => {
        event.preventDefault();
        let d  = [...additional]
        let counter = d.indexOf(index_add);
        d.splice(counter, 1);
        setAdditional(d);
        delete data[index_pac].option_set[index_add];
    }
    const changeHandler = () => {
        data1 = {
            name:ref1.current.value,
            cost:ref.current.value,
        };
        let copy_motor = { ...data[index_pac].option_set, [index_add]: data1}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], option_set: copy_motor}
        setData(copy_data)
    }

    return (
        <div style={{display:'flex', maxWidth:300, marginTop:20}}>
            <input defaultValue={data[index_pac].option_set[index_add] !==null ? (data[index_pac].option_set[index_add]['name']) : ('')} type='text' ref={ref1} onChange={changeHandler} style={{marginRight:10, width: 110}} placeholder="Başlık, ölçü birimi" />
            <input defaultValue={data[index_pac].option_set[index_add] !==null ? (data[index_pac].option_set[index_add]['cost']) : ('')} ref={ref} onChange={changeHandler} type={'number'} onWheel={(event) => {event.currentTarget.blur();}} style={{width: 110}} placeholder="Fiyat" />
            <DeleteIcon style={{color:'white', width:20}} onClick={handlePackageDelete}/>
        </div>
    );
};
const InputPaketOption = ({index_pac, data, setData, set_name, index_add, index_pak, additional, setAdditional}) => {
    const ref1 = useRef()
    let [data1, setData1] = useState([])
    const handlePackageDelete = (event) => {
        event.preventDefault();
        let d  = [...additional]
        let counter = d.indexOf(index_add);
        d.splice(counter, 1);
        setAdditional(d);
        delete data[index_pac][set_name][index_pak].additional[index_add];
    }
    const changeHandler = () => {
        data1 = ref1.current.value;
        if(data[index_pac].paket_set[index_pak] && Object.keys(data[index_pac].paket_set[index_pak]).length !== 0){
            let copy_motor = { ...data[index_pac].paket_set, [index_pak]: {...data[index_pac][set_name][index_pak], additional: {...data[index_pac][set_name][index_pak].additional, [index_add]: data1}}}
            let copy_data = data
            copy_data[index_pac] = {...data[index_pac], paket_set: copy_motor}
            setData(copy_data)
        }else{
            let data_temp = { ...data[index_pac].paket_set, [index_pak]: {
                    name: "",
                    cost: "",
                    additional: null
                }}
            let copy_motor = { ...data_temp, [index_pak]: {...data_temp[index_pak], additional: {...data_temp[index_pak].additional, [index_add]: data1}}}
            let copy_data = data
            copy_data[index_pac] = {...data[index_pac], paket_set: copy_motor}
            setData(copy_data)
        }
    }
    return (
        <div style={{display:'flex', maxWidth:300, marginTop:10}}>
            <input defaultValue={data[index_pac].paket_set[index_pak]['additional'] !==null ? (data[index_pac].paket_set[index_pak]['additional'][index_add]) : ('')} type={"text"} ref={ref1} onChange={changeHandler} style={{marginRight:10, width: 110}} placeholder="Başlık, ölçü birimi" />
            <DeleteIcon style={{color:'white', width:20}} onClick={handlePackageDelete}/>
        </div>
    );
};
const InputPaket = ({index_pac, data, setData, index_pak, pakets, setPakets}) => {
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].paket_set[index_pak]){
            if(data[index_pac].paket_set[index_pak].additional) {
                let keys = Object.keys(data[index_pac].paket_set[index_pak].additional).map(Number);
                setAdditional(keys);
                setAddcounter(Math.max(...keys) + 1);
                // setAddcounter()
            }
        }
    },[])
    const handlePackageDelete = (event) => {
        event.preventDefault();
        let d  = [...pakets]
        let counter = d.indexOf(index_pak);
        d.splice(counter, 1);
        setPakets(d);
        delete data[index_pac].paket_set[index_pak];
    }
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const ref = useRef()
    const ref1 = useRef()
    let [data1, setData1] = useState([])
    const changeHandler = () => {
        data1 = {
            name: ref1.current.value,
            cost: ref.current.value,
        };
        let copy_motor = { ...data[index_pac].paket_set, [index_pak]: {...data[index_pac].paket_set[index_pak], ...data1}}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], paket_set: copy_motor}
        setData(copy_data)
    }

    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <h4 style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}><span>Paket №{index_pak + 1}</span>  <span style={{fontSize:14}}>Количество опций: {additional.length}</span></h4>
            <div style={{display:'flex', maxWidth:300, marginTop:10, marginBottom:10}}>
                <input defaultValue={data[index_pac].paket_set[index_pak] !==null ? (data[index_pac].paket_set[index_pak]['name']) : ('')} type={"text"} ref={ref1} onChange={changeHandler} style={{marginRight:10, width: 110}} placeholder="Başlık, ölçü birimi" />
                <input defaultValue={data[index_pac].paket_set[index_pak] !==null ? (data[index_pac].paket_set[index_pak]['cost']) : ('')} type={"number"} ref={ref} onChange={changeHandler} onWheel={(event) => {event.currentTarget.blur();}} style={{width: 110}} placeholder="Fiyat" />
                <DeleteIcon style={{color:'white', width:20}} onClick={handlePackageDelete}/>
            </div>
            <h4>Paket seçenekleri</h4>
            {
                additional.map(
                    (pack, index) => (
                        // <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'security_set'} />
                        <InputPaketOption additional={additional} setAdditional={setAdditional} key={pack} index_add={pack} index_pac={index_pac} index_pak={index_pak} data={data} setData={setData} set_name={'paket_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
};
const Dimensions = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].dimension_set.additional){
            let keys = Object.keys(data[index_pac].dimension_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].dimension_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], dimension_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[6].hint}>
                <h3>{Hints[6].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Dimension.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].dimension_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].dimension_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'dimension_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Suspensions = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].suspension_set.additional){
            let keys = Object.keys(data[index_pac].suspension_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].suspension_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], suspension_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[5].hint}>
                <h3>{Hints[5].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Suspension.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].suspension_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].suspension_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'suspension_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Transmissions = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].transmission_set.additional){
            let keys = Object.keys(data[index_pac].transmission_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].transmission_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], transmission_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[4].hint}>
                <h3>{Hints[4].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Transmission.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].transmission_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].transmission_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'transmission_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Controls = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].control_set.additional){
            let keys = Object.keys(data[index_pac].control_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].control_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], control_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[3].hint}>
                <h3>{Hints[3].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Control.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].control_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].control_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'control_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Fuels = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].fuel_set.additional){
            let keys = Object.keys(data[index_pac].fuel_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].fuel_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], fuel_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[2].hint}>
                <h3>{Hints[2].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Fuel.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].fuel_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].fuel_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'fuel_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Electrics = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].electric_set.additional){
            let keys = Object.keys(data[index_pac].electric_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].electric_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], electric_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={"Электрическая система"}>
                <h3>Elektrik sistemi</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Electric.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].electric_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].electric_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'electric_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Hybrids = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].hybrid_set.additional){
            let keys = Object.keys(data[index_pac].hybrid_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].hybrid_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], hybrid_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={"Производительность Гибридной системы"}>
                <h3>Hibrit sistem performansı</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Hybrid.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].hybrid_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].hybrid_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'hybrid_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Motors = ({index_pac, data, setData}) =>{
    const [additional, setAdditional] = useState([]);
    const [addcounter, setAddcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].motor_set.additional){
            let keys = Object.keys(data[index_pac].motor_set.additional).map(Number);
            setAdditional(keys);
            setAddcounter(Math.max(...keys) + 1);
            
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = additional.push(addcounter);
        setAddcounter(addcounter + 1)
    };
    const handleFieldChange = (event) => {
        
        let copy_motor = { ...data[index_pac].motor_set, [event.target.name]: event.target.value}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], motor_set: copy_motor}
        setData(copy_data)
        // data[event.target.name] = event.target.value
    }
    return (
        <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <Tooltip title={Hints[1].hint}>
                <h3>{Hints[1].name}</h3>
            </Tooltip>
            <div style={{width:'300px'}}>
                {
                    Motor.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac].motor_set[model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac].motor_set[model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
            </div>
            {
                additional.map(
                    (pack, index) => (
                        // <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1}/>
                        <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'motor_set'} />
                    )
                )
            }
            <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%', marginLeft:20}} className='button' variant='outlined' endIcon={<AddIcon />}>ekle</Button>
            </div>
        </div>
    );
}
const Package = ({index_pac, data, setData, packages, setPackages, counter, setCounter}) =>{
    const [pakets, setPackets] = useState([]);
    const [hybrid, setHybrid] = useState(data[index_pac].fuel_type === 'hibrit benzin & elektrik' || data[index_pac].fuel_type === 'hibrit dizel & elektrik');
    const [pakcounter, addPakcounter] = useState(0);
    useEffect(()=>{
        if(data[index_pac].paket_set && Object.keys(data[index_pac].paket_set).length !== 0){
            let keys = Object.keys(data[index_pac].paket_set).map(Number);
            setPackets(keys);
            addPakcounter(Math.max(...keys) + 1);
            console.log(pakets);
            // setAddcounter()
        }
    },[])
    const onAddBtnClick = event => {
        let temp = pakets.push(pakcounter);
        let copy_motor = { ...data[index_pac].paket_set, [pakcounter]: {name: "", cost:"", additional:null}}
        let copy_data = data
        copy_data[index_pac] = {...data[index_pac], paket_set: copy_motor}
        setData(copy_data)
        addPakcounter(pakcounter + 1)

    };
    const handleFieldChange = (event) => {
        data[index_pac][event.target.name] = event.target.value;
        if(data[index_pac].fuel_type === 'hibrit benzin & elektrik' || data[index_pac].fuel_type === 'hibrit dizel & elektrik'){
            setHybrid(true);
            data[index_pac].electric_set = {additional: null}
            data[index_pac].hybrid_set = {additional: null}
        }else{
            setHybrid(false);
            delete data[index_pac].electric_set;
            delete data[index_pac].hybrid_set;
        }
    }
    const handlePackageDelete = (event) => {
        event.preventDefault();
        let d  = [...packages]
        let counter_temp = d.indexOf(index_pac);
        d.splice(counter_temp, 1);
        setPackages(d);
        delete data[index_pac];
        console.log(packages);
        
    }
    const onCopyBtnClick = event => {
        let temp_package = {}
        if(data[index_pac].electic_set){
            temp_package = {
                ...data[index_pac],
                motor_set:{...data[index_pac].motor_set, additional: {...data[index_pac].motor_set.additional}},
                electric_set: {...data[index_pac].electric_set, additional: {...data[index_pac].electric_set.additional}},
                hybrid_set: {...data[index_pac].hybrid_set, additional: {...data[index_pac].hybrid_set.additional}},
                fuel_set:{...data[index_pac].fuel_set, additional: {...data[index_pac].fuel_set.additional}},
                control_set:{...data[index_pac].control_set, additional: {...data[index_pac].control_set.additional}},
                transmission_set:{...data[index_pac].transmission_set, additional: {...data[index_pac].transmission_set.additional}},
                suspension_set:{...data[index_pac].suspension_set, additional: {...data[index_pac].suspension_set.additional}},
                dimension_set:{...data[index_pac].dimension_set, additional: {...data[index_pac].dimension_set.additional}},
                offroad_set:{...data[index_pac].offroad_set, additional: {...data[index_pac].offroad_set.additional}},
                still_set:{...data[index_pac].still_set, additional: {...data[index_pac].still_set.additional}},
                comfort_set:{...data[index_pac].comfort_set, additional: {...data[index_pac].comfort_set.additional}},
                security_set:{...data[index_pac].security_set, additional: {...data[index_pac].security_set.additional}},
                paket_set:{},
                option_set:{},
            }
        }else{
            temp_package = {
                ...data[index_pac],
                motor_set:{...data[index_pac].motor_set, additional: {...data[index_pac].motor_set.additional}},
                fuel_set:{...data[index_pac].fuel_set, additional: {...data[index_pac].fuel_set.additional}},
                control_set:{...data[index_pac].control_set, additional: {...data[index_pac].control_set.additional}},
                transmission_set:{...data[index_pac].transmission_set, additional: {...data[index_pac].transmission_set.additional}},
                suspension_set:{...data[index_pac].suspension_set, additional: {...data[index_pac].suspension_set.additional}},
                dimension_set:{...data[index_pac].dimension_set, additional: {...data[index_pac].dimension_set.additional}},
                offroad_set:{...data[index_pac].offroad_set, additional: {...data[index_pac].offroad_set.additional}},
                still_set:{...data[index_pac].still_set, additional: {...data[index_pac].still_set.additional}},
                comfort_set:{...data[index_pac].comfort_set, additional: {...data[index_pac].comfort_set.additional}},
                security_set:{...data[index_pac].security_set, additional: {...data[index_pac].security_set.additional}},
                paket_set:{},
                option_set:{},
            }
        }
        if(data[index_pac].paket_set) {
            let paket_keys = Object.keys(data[index_pac].paket_set)
            for (let i of paket_keys) {
                temp_package['paket_set'][i] = {...data[index_pac].paket_set[i], additional: {...data[index_pac].paket_set[i].additional}}
            }
        }
        if(data[index_pac].option_set) {
            let option_keys = Object.keys(data[index_pac].option_set)
            for (let i of option_keys) {
                temp_package['option_set'][i] = {...data[index_pac].option_set[i]}
            }
        }
        data.push(temp_package);
        // setPackages(packages.concat(<Package packages={packages} setPackages={setPackages} key={packages.length} index_pac={packages.length} data={packages1} setData={setPackages1}/>));
        let temp = packages.push(counter);
        setCounter(counter + 1);
        // setPackages(temp);
    };
    return (
        <div className={'window-grey'} style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
            <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                <Tooltip title={Hints[0].hint}>
                    <h3>{Hints[0].name}</h3>
                </Tooltip>
                <Button onClick={onCopyBtnClick} style={{marginLeft:'20px'}} className='button' variant='outlined' endIcon={<AddIcon />}>Copy</Button>
                <DeleteIcon style={{color:'white', width:20}} onClick={handlePackageDelete}/>
            </div>
            <div style={{width:'300px'}}>
                {/*<p>Бренд+Модель+ Название комплектации + Объем двигателя (л)+ Индекс двигателя + лошадиные силы + Привод +Коробка+ Тип топлива+Кол-во мест если есть</p>*/}
                <FormControl style={{color:'white'}}>
                    <FormLabel  style={{color:'white'}} id="demo-row-radio-buttons-group-label">ön sipariş(Предзаказ)</FormLabel>
                    <RadioGroup
                        defaultValue={false}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        onChange={handleFieldChange}
                        name="order"
                    >
                        <FormControlLabel value={false} control={<Radio />} label="Numara(нет)" />
                        <FormControlLabel value={true} control={<Radio />} label="Evet(да)" />
                    </RadioGroup>
                </FormControl>
                {
                    Packages.map((model,index)=>(
                        <div style={{display:'flex', flexDirection:'column'}}>
                            {
                                model.type==="select" ?
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <select defaultValue={data[index_pac][model.name] || ''} name={model.name} onChange={handleFieldChange} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                    <option></option>
                                                    {
                                                        model.options.map((option,index)=>(
                                                            <option>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>

                                    )
                                    :
                                    (
                                        <>
                                            <label>{model.label}</label>
                                            <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                <input defaultValue={data[index_pac][model.name] || ''} style={{width:'100%'}} name={model.name} onChange={handleFieldChange} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                <Tooltip title={model.hint} style={{}}>
                                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                        <LiveHelpIcon style={{color:'white', width:15}}/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )
                            }
                        </div>)
                    )
                }
                <Motors data={data} index_pac={index_pac} setData={setData}/>
                {
                    hybrid &&
                        (
                            <>
                                <Electrics data={data} index_pac={index_pac} setData={setData}/>
                                <Hybrids data={data} index_pac={index_pac} setData={setData}/>
                            </>
                        )
                }
                <Fuels data={data} index_pac={index_pac} setData={setData}/>
                <Controls data={data} index_pac={index_pac} setData={setData}/>
                <Transmissions data={data} index_pac={index_pac} setData={setData}/>
                <Suspensions data={data} index_pac={index_pac} setData={setData}/>
                <Dimensions data={data} index_pac={index_pac} setData={setData}/>
                <OffRoads data={data} index_pac={index_pac} setData={setData}/>
                <Stil data={data} index_pac={index_pac} setData={setData}/>
                <Comfort data={data} index_pac={index_pac} setData={setData}/>
                <Security data={data} index_pac={index_pac} setData={setData}/>
                <Options data={data} index_pac={index_pac} setData={setData}/>
                <div style={{display:"flex", flexDirection:"column", marginTop:30, marginRight:30}}>
                    <Tooltip title="дополнительный пакет">
                        <h3>Paketler</h3>
                    </Tooltip>
                    {
                        pakets.map(
                            (pack, index) => (
                                // <Input key={pack} additional={additional} setAdditional={setAdditional} index_add={pack} index_pac={index_pac} data={data} setData={setData} set_name={'security_set'} />
                                <InputPaket pakets={pakets} setPakets={setPackets} key={pack} index_pak={pack} index_pac={index_pac} data={data} setData={setData}/>                            )
                        )
                    }
                    <div style={{marginTop:10, width:'100%', display:'flex', justifyContent:'center'}}>
                        <Button onClick={onAddBtnClick} style={{marginTop:20, width:'100%'}} className='button' variant='outlined' endIcon={<AddIcon />}>Paket ekle</Button>
                    </div>
                </div>
                <div style={{width:'300px'}}>
                    <label>Примечание</label>
                    <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                        <input defaultValue={data[index_pac]['comment'] || ''} style={{width:'100%'}} name={"comment"} onChange={handleFieldChange} type={"text"} placeholder={"Примечание"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const CreateCar = () => {
    const history = useNavigate();
    const [packages, setPackages] = useState([]);
    const [counter, setCounter] = useState(0);
    const [packages1, setPackages1] = useState([]);
    const [model1, setModel1] = useState({});
    const [euroNcap1, setEuroNcap1] = useState({});
    let [gallery, setGallery] = useState([]);
    const [error, setError] = useState([]);
    const param = useParams();
    const onAddBtnClick = event => {
        packages1.push({
            motor_set:{additional: null},
            fuel_set:{additional: null},
            control_set:{additional: null},
            transmission_set:{additional: null},
            suspension_set:{additional: null},
            dimension_set:{additional: null},
            offroad_set:{additional: null},
            still_set:{additional: null},
            comfort_set:{additional: null},
            security_set:{additional: null},
            paket_set:{},
            option_set:{},
        })
        // setPackages(packages.concat(<Package packages={packages} setPackages={setPackages} key={packages.length} index_pac={packages.length} data={packages1} setData={setPackages1}/>));
        let temp = packages.push(counter);
        setCounter(counter + 1)
        // setPackages(temp);
    };
    const onChangeHandler=event=>{
        setModel1({...model1,[event.target.name]:event.target.value});
        console.log(model1);
    }

    const onChangeHandlerEuroncap=event=>{
        setEuroNcap1({...euroNcap1,[event.target.name]:event.target.value});
    }
    const [image, setImage] = useState([])
    const [gallery_pack, setGallery_pack] = useState(0);
    const handleGallery = event => {
        let temp = event.target.files;
        gallery.push(...temp);
        setGallery_pack(gallery.length);
        if (event.target.files && event.target.files) {
            let temp_photo = [];
            for(let images = 0; images < gallery.length; images++){
                temp_photo.push(URL.createObjectURL(gallery[images]));
            }
            setImage(temp_photo);
        }
        if(gallery.length === 0){
            makeMain(0);
        }
    }

    const deletePhoto = index => {
        let temp_array = gallery;
        temp_array.splice(index, 1);
        setGallery_pack(temp_array.length);
        gallery = [];
        gallery = temp_array;
        let temp_photo = [];
        for(let images = 0; images < gallery.length; images++){
            temp_photo.push(URL.createObjectURL(gallery[images]));
        }
        setImage(temp_photo);
        makeMain(0);
    }

    const makeMain = index => {
        const temp = [];
        for(let file = 0; file < gallery.length; file ++){
            if(index === file){
                let mainPhoto = new File(
                    [gallery[file]],
                    `${param.brand}_${model1.name}_${model1.body}_${file}_main.${gallery[file].name.split('.').reverse()[0]}`,
                    {type: gallery[file].type}
                );
                temp.push(mainPhoto);
            }else{
                let Photo = new File(
                    [gallery[file]],
                    `${param.brand}_${model1.name}_${model1.body}_${file}.${gallery[file].name.split('.').reverse()[0]}`,
                    {type: gallery[file].type}
                );
                temp.push(Photo);
            }
        }
        setGallery(temp);
    }

    const submitData = event => {
        console.log(gallery);
        console.log(model1);
        // Create an object of formData
        const formData = new FormData();
        for(let file = 0; file < gallery.length; file++){
            formData.append(`${gallery[file].name}`, gallery[file]);
        }
        console.log(packages1);
        axios.post(`https://libertpro.com/brands/${param.id}/models/new/`,
            {
                model: model1,
                euroncap_set: euroNcap1,
                package_set: packages1,
            }).then(
            result=>{
                console.log(result);
                axios.post(`https://libertpro.com/brands/${param.id}/models/${result.data.model_id}/gallery/`,
                    formData,{headers:{'Content-Type':'multipart/form-data'}}).then(
                    result=>{
                        console.log(result);
                        history(`/brand/models/${param.id}`);
                    }
                ).catch(err=>{
                    console.log(err);
                });
            }
        ).catch(err=>{
            const errors = Object.keys(err.response.data);
            const errors_text = err.response.data;
            const text_errors = [];
            console.log(errors);
            for(let error = 0; error < errors.length; error++){
                Model.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                EuroNcap.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Packages.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Model.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Motor.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Fuel.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Control.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Transmission.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Suspension.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                Dimension.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
                OffRoad.map((mod)=>{
                    if(errors[error] === mod.name){
                        
                        console.log(err.response.data);
                        text_errors.push(`${mod.label} ${err.response.data[errors[error]]}`);
                    }
                })
            }
            setError(text_errors);
        });
    }
    return(
        <div style={{overflow:"auto"}}>
            <div className='brand-head' style={{marginBottom:0}}>
                <h2>Araçla ilgili tüm bilgiler</h2>
                <p style={{color:'white', display:'flex', alignItems:'center'}}><a href={'/'}>ana Sayfa</a><KeyboardArrowRightIcon/><a href={`/brand/${param.car}/models/${param.id}`}>marka</a><KeyboardArrowRightIcon/>model</p>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Alert style={error.length === 0 ? {opacity:0, marginTop:0, width:400, maxHeight:300, position:'absolute'} : {opacity:1, marginTop:0, width:400, position:'absolute',top:40, zIndex:100}} severity="error">{error.map((err, index)=>(
                    <p style={{margin:0}}>{err}</p>
                ))}</Alert>
            </div>
            <div style={{overflowY:'auto', height:'74vh', maxWidth:1200, margin:'0 auto'}}>
                <div style={{display:'flex', flexDirection:'row'}} className={'window-grey'}>
                    <div style={{width:'50%'}}>
                        <h3>Genel bilgi</h3>
                        <form>
                            {
                                Model.map((model,index)=>(
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        {
                                            model.type==="select" ?
                                                (
                                                    <>
                                                        <label>{model.label}</label>
                                                        <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                            <select name={model.name} onChange={onChangeHandler} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                                <option></option>
                                                                {
                                                                    model.options.map((option,index)=>(
                                                                        <option>{option}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <Tooltip title={model.hint} style={{}}>
                                                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                                    <LiveHelpIcon style={{color:'white', width:15}}/>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </>

                                                )
                                                :
                                                (
                                                    <>
                                                        <label>{model.label}</label>
                                                        <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                            <input style={{width:'100%'}} name={model.name} onChange={onChangeHandler} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                            <Tooltip title={model.hint} style={{}}>
                                                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                                    <LiveHelpIcon style={{color:'white', width:15}}/>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </>
                                                )
                                        }
                                    </div>)
                                )
                            }
                            <form style={{marginTop:10}} encType='multipart/form-data'>
                                <div style={{marginTop:20}}>
                                    <label style={{fontSize:16, border:'1px solid white', padding:4, borderRadius:4, marginRight:10}} className='button' htmlFor='gallery'>Fotoğraf ekle</label>
                                    <label>fotoğraf sayısı: {gallery_pack}</label>
                                </div>
                                <input disabled={!(model1.name && model1.body)} id='gallery' hidden={true} accept="image/*" style={{color:'white'}} name='1' type={'file'} multiple onChange={handleGallery}/>
                                <div style={{marginTop:20, display:'flex', flexDirection:'row'}}>
                                {
                                    image.length !== 0 && (
                                        image.map((photo, index)=>(
                                            <div style={{position:'relative', display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                                <CloseIcon onClick={()=>{deletePhoto(index)}} style={{color:'white', position:'absolute', right:4, top:4, background:'black'}}/>
                                                <img style={{width:50,height:50, margin:10}} src={photo}/>
                                                <input type="radio" name='main_photo' onChange={()=>{makeMain(index)}}/>
                                            </div>
                                        ))
                                    )
                                }
                                </div>
                            </form>
                        </form>
                    </div>
                    <div style={{width:'50%'}}>
                        <h3>euro Ncap</h3>
                        <form>
                            {
                                EuroNcap.map((model,index)=>(
                                    <div style={{display:'flex', flexDirection:'column'}}>
                                        {
                                            model.type==="select" ?
                                                (
                                                    <>
                                                        <label>{model.label}</label>
                                                        <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                            <select name={model.name} onChange={onChangeHandlerEuroncap} style={{width:'100%', borderRight:'20px solid transparent'}}>
                                                                <option></option>
                                                                {
                                                                    model.options.map((option,index)=>(
                                                                        <option>{option}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <Tooltip title={model.hint} style={{}}>
                                                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                                    <LiveHelpIcon style={{color:'white', width:15}}/>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </>

                                                )
                                                :
                                                (
                                                    <>
                                                        <label>{model.label}</label>
                                                        <div style={{display:'flex', flexDirection:'row', width:'100%', position:'relative'}}>
                                                            <input style={{width:'100%'}} name={model.name} onChange={onChangeHandlerEuroncap} type={model.type} onWheel={(event) => {event.currentTarget.blur();}} placeholder={model.placeholder}/>
                                                            <Tooltip title={model.hint} style={{}}>
                                                                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', position:'absolute', right:5, top:5}}>
                                                                    <LiveHelpIcon style={{color:'white', width:15}}/>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </>
                                                )
                                        }
                                    </div>)
                                )
                            }
                        </form>
                    </div>
                </div>
                <div style={{display:'flex', flexDirection:'column', marginTop:30}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <h3>Tam takım</h3>
                        <Button onClick={onAddBtnClick} style={{marginLeft:'20px'}} className='button' variant='outlined' endIcon={<AddIcon />}>Teçhizat ekle</Button>
                    </div>

                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                        {
                            packages.map(
                                (pack, index) => (
                                    <Package packages={packages} setPackages={setPackages} key={pack} index_pac={pack} data={packages1} setData={setPackages1} counter={counter} setCounter={setCounter}/>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
            <div style={{width:'100%', marginTop:20, display:'flex', justifyContent:'center'}}>
                <Button onClick={submitData} style={{background:'green', color:'white', width:'300px'}}>veri kaydet</Button>
            </div>
        </div>
    )
}
