import {createContext, useState} from 'react';
import axios from "axios";

const AuthContext = createContext({});

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
if (localStorage && localStorage.token) {
    const access_token = JSON.parse(localStorage.getItem('token'));
    axios.defaults.headers.common['Authorization'] = `Token ${access_token}`;
}
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({})
    const [me, setMe] = useState({})
    return(
        <AuthContext.Provider value={{auth, setAuth, me, setMe}}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext;
