import React from 'react';
import {Navigate, Route, Routes} from "react-router";
import {Auth} from "./pages/auth/Auth";
import {AllCars} from "./pages/all_cars/AllCars";
import {CreateCar} from "./pages/create_car/CreateCar";
import {BrandsTable} from "./pages/all_brands/BrandsTable";
import {EditCar} from "./pages/create_car/EditCar";

export const useRoutes = ({is_authenticated}) => {
    if(is_authenticated){
        return(
            <Routes>
                <Route path="/all_brands" element={<BrandsTable/>}/>
                <Route path="/brand/:brand/models/:id" element={<AllCars/>}/>
                <Route path="/create_car/:brand/:id" element={<CreateCar/>}/>
                <Route path="/edit_car/:brand/:id/:car" element={<EditCar/>}/>
                <Route path="/" element={<BrandsTable/>}/>
                <Route path="*" element={<Navigate to="/"/>}/>
            </Routes>
        )
    }
    return (
        <Routes>
            <Route path="/" element={<Auth/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    )
}
