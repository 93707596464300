import React, {useState} from 'react';
import axios from "axios";

export const Auth = () => {
    const [data, setData] = useState({
        username:'',
        password:'',
    });
    const [err, setErr] = useState(false);
    const changeHandler = event => {
        setErr(false);
        setData({...data, [event.target.name]:event.target.value});
    }
    const submitHandler = event => {
        event.preventDefault();
        axios.post('https://libertpro.com/auth/', {...data}).then(result=>{
            localStorage.setItem('token', JSON.stringify(result.data.token));
            window.location.reload();
        }).catch((
            error=>{
                if(error){
                    setErr(true);
                }
            }
        ))
    }
    return(
        <div>
            <form className='auth' onSubmit={submitHandler}>
                <p style={err ? {color:'red', opacity:1} : {color:'red', opacity:0}}>Не верный логин или пароль</p>
                <input placeholder='user_name' name='username' value={data.username} onChange={changeHandler}/>
                <input type="password" placeholder='password' name='password' value={data.password} onChange={changeHandler}/>
                <button type='submit'>Войти</button>
            </form>
        </div>
    )
}
