import React from 'react';
import {useRoutes} from "./routes";
import {BrowserRouter as Router} from "react-router-dom";

function App() {
  const is_authenticated = localStorage.getItem('token');
  const routes = useRoutes({is_authenticated});
  return (
    <div className='container'>
      <Router>
          {routes}
      </Router>
    </div>
  );
}

export default App;
