import React, {useEffect, useState} from 'react';
import './brands.scss';
import {Button, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import axios from "axios";
import {useNavigate} from "react-router";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    borderRadius:2,
    boxShadow: 24,
    p: 2,
};


export const BrandsTable = () => {
    const history = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [err, setErr] = useState(false);
    const [brand, setBrand] = useState({
        name:''
    })
    const [brands, setBrands] = useState([]);

    useEffect(()=>{
        axios.get('https://libertpro.com/brands/').then(
            result => {
                setBrands(result.data);
            }
        ).catch(
            err=>{console.log(err)}
        )
    },[])

    const changeHandler = event => {
        setErr(false);
        setBrand({...brand, [event.target.name]:event.target.value})
    }

    const sendData = () =>{
        if(brand.name === ''){
            setErr(true);
        }
        axios.post('https://libertpro.com/brands/new/', {...brand}).then(
            result => {handleClose();}
        ).catch(
            err=>{console.log(err)}
        )
    }

    return(
        <div style={{width:'100%'}}>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <h3 style={{margin:0}}>Araba Markaları</h3>
                        <CloseIcon onClick={handleClose}/>
                    </div>
                    <p style={err ? {color:'red'}:{color:'black'}}>{err ? ('Alan boş olamaz'):('Arabanın markasını girin')}</p>
                    <TextField name='name' value={brand.name} onChange={changeHandler} style={{width:'100%', padding:0, marginTop:0}}/>
                    <Button onClick={sendData} style={{width:'100%', color:'white', background:'black', marginTop:20}} variant='outlined'>
                        ekle
                    </Button>
                </Box>
            </Modal>

            <div className='brand-head'>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                    <h2>Araba Markaları</h2>
                    <Button onClick={handleOpen} style={{marginLeft:'50px'}} className='button' variant='outlined' endIcon={<AddIcon />}>marka ekle</Button>
                </div>
            </div>
            <div className={'brand_grid'} style={{overflowY:'auto', height:'80vh', marginTop: 40}}>
                {
                    brands.map((brand)=>(
                        <Button className={'button-filled'} key={brand.id} onClick={()=>{history(`/brand/${brand.name}/models/${brand.id}`)}}>
                            {brand.name}
                        </Button>
                    ))
                }
            </div>
        </div>
    )
}
